import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext } from 'react';
import { ReactComponent as LogoPetSVG } from '../../../svg/Pet_Support_logo_svart.svg';
import { ReactComponent as LogoPetWhiteSVG } from '../../../svg/Pet_Support_logo_Vit.svg';
import { ReactComponent as LogoSVG } from '../../../svg/Logo.svg';
import { ReactComponent as LogoWhiteSVG } from '../../../svg/Logo_white.svg';

const Logo = ({ secondary = false, size = [400] }) => {
  const { selectedChannel } = useContext(ChannelContext);
  if (selectedChannel.id === 2) {
    if (secondary) return <LogoPetWhiteSVG />;
    return <LogoPetSVG />;
  }
  if (secondary) return <LogoWhiteSVG />;
  return <LogoSVG />;
};

export default Logo;
