import t from '@jetshop/intl';
import ChannelSelector from '@jetshop/ui/ChannelSelector/ChannelSelector';
import CheckboxGroup, {
  CheckboxWrapper
} from '@jetshop/ui/Checkbox/CheckboxGroup';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import flattenCountries from './flattenCountries';
import Button from '../../../ui/Button';

const Title = styled('h3')``;

const SelectorWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  padding: 1rem;
  width: 320px;
  background-color: white;
  border: 1px solid #eee;
  &.modal {
    width: 100%;
    //max-width: 900px;
    //margin: 0 auto;
  }
  ${CheckboxWrapper} {
    margin-top: 1rem;
    &:first-child {
      margin-top: 0;
    }
  }
  ${Title} {
    text-align: center;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
  }
`;

const SelectorBody = styled('div')`
  margin-bottom: 1rem;
`;

const LangCurrWrapper = styled('div')`
  margin-bottom: 1rem;
  padding: 1rem;
  background: #f5f5f5;
`;

const Country = styled('div')`
  height: 42px;
  font-size: 14px;
  background-color: ${({ isActive }) => (isActive ? '#ebebeb' : 'transparent')};
  padding: 0 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  color: ${({ isActive }) => (isActive ? '#000' : '#4f4f4f')};
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  opacity: ${({ isActive }) => (isActive ? '1' : '0.75')};
  :hover {
    opacity: 1;
  }
`;

const CountryFlag = styled('img')`
  position: relative;
  top: -2px;
  width: 20px;
  margin-right: 15px;
`;

const ChecboxGroupContainer = styled('div')`
  & > div {
    position: relative;
    padding-left: 80px;
    & > span {
      position: absolute;
      top: 0;
      left: 0;
      color: #000;
      & ~ label {
        margin-top: 0.6rem;
        color: #000;
      }
      & + label {
        margin-top: 0;
      }
    }
  }
  input ~ span {
    position: relative;
    top: -2px;
    border: 1px solid #222;
    svg {
      height: 8px;
    }
  }
  label input:checked ~ span {
    background-color: #222;
  }
`;

const CommitButton = styled(Button)``;

const CancelButton = styled(Button)``;

const Selector = ({
  channels,
  selectedChannel,
  hideTarget,
  updateChannel,
  className
}) => {
  const [countries] = useState(flattenCountries(channels));
  return (
    <ChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({
        channels,
        currencyItems,
        languageItems,
        selectedChannel,
        onSelect,
        hasChanged,
        onCurrencySelect,
        onLanguageSelect
      }) => (
        <SelectorWrapper className={className}>
          <SelectorBody>
            <Title>{t('Select your country')}</Title>
            <div class="channel-list">
              {countries.map(({ name, code, channel }) => (
                <Country
                  key={channel.id + name}
                  isActive={
                    channel.id === selectedChannel.id &&
                    name === selectedChannel.country.name
                  }
                  onClick={() => onSelect(channel.id, null, null, code)}
                >
                  <CountryFlag
                    src={`https://countryflags.jetshop.io/${code}/flat/32.png`}
                    alt="Country Flag"
                  />
                  {name}
                </Country>
              ))}
            </div>
          </SelectorBody>
          <LangCurrWrapper>
            <ChecboxGroupContainer>
              <CheckboxGroup
                selectedItem={selectedChannel.language.name}
                items={languageItems}
                handleChange={onLanguageSelect}
                groupLabel={t('Language')}
              />
              <CheckboxGroup
                selectedItem={selectedChannel.currency.name}
                items={currencyItems}
                handleChange={onCurrencySelect}
                groupLabel={t('Currency')}
              />
            </ChecboxGroupContainer>
          </LangCurrWrapper>
          {hasChanged ? (
            <CommitButton
              cta
              onClick={() => {
                updateChannel(selectedChannel);
                hideTarget();
              }}
              text={t('Update')}
            />
          ) : (
            <CancelButton text={t('Close')} onClick={() => hideTarget()} />
          )}
        </SelectorWrapper>
      )}
    />
  );
};

export default Selector;
