import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { ContentHtml, contentTextElements } from '../ContentText';
import { ContentLink } from '../ContentLink';

export const RowHeaderWrapper = styled.header`
  text-align: center;
  margin: 3rem 0 2rem;
  ${theme.above.lg} {
    margin: 4rem 0 3rem;
  }
  ${contentTextElements} {
    color: ${theme.colors.primaryDark};
  }
`;

export const RowHeader = ({ props, children, className = '' }) => {
  const content = props.header.value;
  const link = props.headerLink.value;

  if (!content) return null;

  return (
    <RowHeaderWrapper className={`row-header content-html-text ${className}`}>
      {link ? (
        <ContentLink to={link}>
          <ContentHtml content={content} />
        </ContentLink>
      ) : (
        <ContentHtml content={content} />
      )}
      {children}
    </RowHeaderWrapper>
  );
};
