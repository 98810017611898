import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { styled } from 'linaria/react';
import { GridPrice } from '../Price';
import t from '@jetshop/intl';
import { Favourite } from '../ProductList/Favourite';
import { theme } from '../Theming/Theming';

export const imageOverlay = `
  position:relative;
  padding: 2px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #7d190008;
  }
`;

export const ProductCardInner = styled('div')`
  position: relative;
  line-height: 1;
  min-width: 0;
  > a {
    ${imageOverlay}
    color: inherit;
    display: block;
    background-color: ${theme.productGrid.product.background};
    border: ${theme.productGrid.product.border};
    padding: ${theme.productGrid.product.padding.sm};
    ${theme.above.md} {
      padding: ${theme.productGrid.product.padding.md};
    }
    ${theme.above.xl} {
      padding: ${theme.productGrid.product.padding.lg};
    }
  }
  .product-card-image {
    padding: 7%;
  }
`;

export const Details = styled('div')`
  margin: 1rem 0 0.5rem;
  position: relative;
`;

export const DetailsInner = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-end;
  > * {
    flex-grow: 0;
    width: 100%;
  }
`;

export const BadgeWrapper = styled('div')`
  color: white;
  text-align: center;
  font-size: 0.8rem;
  ${theme.below.md} {
    img {
      max-width: 50px;
    }
  }
`;

export const Name = styled('h5')`
  margin: 0 0 0.7rem 0 !important;
  padding: 0;
  padding-right: 1.8rem;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.5;
  font-family: ${theme.productGrid.product.name.fontFamily} !important;
  font-weight: ${theme.productGrid.product.name.fontWeight} !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const SubName = styled('p')`
  margin: 0 0 1rem 0;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #aaa;
  font-weight: ${theme.weights.medium};
  ${theme.below.md} {
    font-size: 0.9rem;
  }
`;
export const ListPrice = styled(GridPrice)``;

const PackagePrice = styled('div')`
  font-size: 0.8rem;
  margin-top: 5px;
`;

const ProductCardComponent = ({
  product,
  imageAspect,
  imageSizes,
  children,
  showFavorite,
  showVariants,
  ...linkProps
}) => {
  //HAS IMAGES
  const hasImages = product.images && product.images.length > 0;

  //STANDARD PRODUCT IMAGE
  let images = product && product.images;

  let badges = [...product.badges];

  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  return (
    <ProductCardInner className="product-card-inner">
      <ProductLink product={product} {...linkProps}>
        {hasImages ? (
          <div className="product-card-image">
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={images[0].alt}
              src={images[0].url}
            >
              <BadgeWrapper>
                <Badges badges={product.badges} />
              </BadgeWrapper>
            </Image>
          </div>
        ) : (
          <Image src={transparentDataImg} />
        )}
        <Details className="product-card-detail">
          {showFavorite && (
            <Favourite
              product={product}
              style={{
                position: 'absolute',
                bottom: '0',
                top: 'auto'
              }}
            />
          )}
          <DetailsInner>
            <header>
              <SubName className="sub-name">{product.subName}</SubName>
              <Name>{product.name}</Name>
            </header>
            {product.isPackage && (
              <PackagePrice className="package-price-label">
                {t('Package Price')}
              </PackagePrice>
            )}
            <ListPrice
              price={product.price}
              previousPrice={product.previousPrice}
            />
          </DetailsInner>
        </Details>
      </ProductLink>
      {children}
    </ProductCardInner>
  );
};

export function ProductCard({
  product,
  className = '',
  imageAspect = theme.productGrid.product.imageAspect,
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'div',
  children,
  showFavorite = true,
  showVariants = true,
  ...linkProps
}) {
  const Tag = as;

  //PROPS TO PASS TO PRODUCTCARD COMP
  const props = {
    imageAspect,
    imageSizes,
    children,
    showFavorite,
    showVariants,
    ...linkProps
  };

  return (
    <Tag
      className={`product-card ${className}`}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductCardComponent product={product} {...props} />
    </Tag>
  );
}
