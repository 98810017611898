import React from 'react';
import { useConstructImage } from '@jetshop/ui/Image/useConstructImage';
import { theme } from '../Theming/Theming';

/*
<InlineImage
  src="/pub_docs/files/Custom_Item_Images/hero.jpg"
  sizes={[100, 200, 300, 400, 500]}
/>
*/

export const InlineImage = ({
  sizes = [1],
  src,
  alt = '',
  quality = 80,
  className
}) => {
  const originalSrc = src;
  const sizesArray = sizes;
  const imageQuery = useConstructImage({
    crop: false,
    src: originalSrc,
    theme,
    sizes: sizesArray,
    aspect: '1:10',
    quality
  });

  return (
    <div data-flight-inline-image-holder="" className={className}>
      <picture data-flight-inline-image="">
        <source
          type="image/webp"
          srcset={imageQuery.webpSrcset}
          sizes={imageQuery.sizes}
        />
        <source srcSet={imageQuery.srcset} sizes={imageQuery.sizes} />
        <img
          alt={alt}
          sizes={imageQuery.sizes}
          srcSet={imageQuery.srcset}
          src={imageQuery.src}
          loading="lazy"
        />
      </picture>
    </div>
  );
};
