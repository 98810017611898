import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBag } from '@fortawesome/pro-light-svg-icons';

const CartIcon = ({ className = '', ...rest }) => {
  return (
    <FontAwesomeIcon icon={faShoppingBag} {...rest} className={className} />
  );
};

export default CartIcon;
