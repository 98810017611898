import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';

export const ProductListHeader = styled('h1')`
  padding: 1.5rem 0 1.5rem;
  width: 100%;
  text-align: center;
  color: ${theme.productPage.productList.headerColor};
  font-family: ${theme.startPage.headers.fontFamily} !important;
  font-weight: ${theme.startPage.headers.fontWeight} !important;
`;

export const ProductListSubHeader = styled('h3')`
  padding: 0 0;
  width: 100%;
  text-align: center;
  color: ${theme.productPage.productList.subHeaderColor};
`;

export const ProductInfoMarginBottom = `
  margin-bottom: 0.7rem;
  ${theme.above.md} {
    margin-bottom: 1.5rem;
  }
`;
