import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { useSiteContent } from '../../Util/useSiteContent';

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 ${theme.general.pagePadding.lg};
  ${theme.below.sm} {
    flex-direction: column;
  }
  > div {
    width: 100%;
    padding: 2rem 0;
    ${theme.only.md} {
      width: 40%;
    }
    ${theme.above.lg} {
      width: 15%;
      padding: 3rem 0;
      &:first-child {
        width: 40%;
      }
    }
    > h2,
    > h3,
    > h4,
    > h5 {
      height: 60px;
    }
    > img {
      height: 40px;
      margin-bottom: 20px;
    }

    > ul {
      > li {
        line-height: 2em;
      }
    }
    p {
      line-height: 2em;
      margin-bottom: 1rem;
    }
  }
`;

const FooterContent = () => {
  const item = useSiteContent(['Footer']);

  if (!item) return null;

  return <FooterContentWrapper>{item.html}</FooterContentWrapper>;
};

export default FooterContent;
