import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import TypekitFont from './Util/Fonts/TypekitFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import routeQuery from './RouteQuery.gql';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import './Theming/globalStyles';
import { config } from '../shop.config.custom';
import { theme } from './Theming/Theming';
import CookieConsent from './Util/CookieConsent';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />
});

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />
  }
);

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});

//BLOG IMPORTS
const LoadableBlogPage = loadable(() => import('./Blog/BlogPage'), {
  fallback: <LoadingPage />
});

const LoadableBlogArticle = loadable(() => import('./Blog/BlogArticle'), {
  fallback: <LoadingPage />
});

function Shop() {
  const { routes } = useShopConfig();
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <GenericError>
      <ModalProvider>
        <Container>
          <LoadingBar />
          <TypekitFont
            primaryFont={theme.font.primary}
            secondaryFonts={[theme.font.secondary]}
            id="bgv3zcb"
          />
          <Helmet titleTemplate={`%s`} defaultTitle={config.settings.storeName}>
            {selectedChannel.id === 2 && (
              <link
                rel="shortcut icon"
                href="/pub_images/original/favicon_petsupport.png"
              />
            )}
          </Helmet>
          <ProductListProvider queries={productListQueries}>
            <PaginationProvider defaultProductsPerPage={24}>
              <Header />
              <Content>
                <Switch>
                  <Route exact path={`${config.routes.blog.path}/:cat/:item`}>
                    <LoadableBlogArticle configObject={config.routes.blog} />
                  </Route>
                  <Route exact path={`${config.routes.blog.path}/:cat`}>
                    <LoadableBlogPage configObject={config.routes.blog} />
                  </Route>
                  <Route exact path={config.routes.blog.path}>
                    <LoadableBlogPage configObject={config.routes.blog} />
                  </Route>
                  <Route exact path="/">
                    <LoadableStartPage />
                  </Route>
                  <Route path={config.routes.favourites.path}>
                    <Favourites />
                  </Route>
                  <Route path={routes.search.path}>
                    <LoadableSearchPage />
                  </Route>
                  <Route path={routes.signup.path}>
                    <LoadableSignUpPage />
                  </Route>
                  <Route path={routes.login.path}>
                    <LogInPage />
                  </Route>
                  <Route path={routes.logout.path}>
                    <LogOutPage />
                  </Route>
                  <Route path={routes.stores.path}>
                    <StoreLocator />
                  </Route>
                  <Route path={`${routes.store.path}/:id`}>
                    <Store />
                  </Route>
                  <Route path={routes.myPages.path}>
                    <LoadableMyPages />
                  </Route>
                  <Route exact path={routes.forgotPassword.path}>
                    <ForgotPassword />
                  </Route>
                  <Route path={`${routes.resetPassword.path}/:token`}>
                    <ResetPassword />
                  </Route>
                  <Route
                    path="/preview"
                    render={props => (
                      <PreviewRoute
                        productQuery={ProductPreviewQuery}
                        categoryQuery={CategoryPreviewQuery}
                        pageQuery={PagePreviewQuery}
                        productPage={LoadableProductPage}
                        categoryPage={LoadableCategoryPage}
                        contentPage={LoadableContentPage}
                        StartPage={LoadableStartPage}
                        {...props}
                      />
                    )}
                  />
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={LoadableProductPage}
                    categoryPage={LoadableCategoryPage}
                    contentPage={LoadableContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </Content>
              <Footer />
            </PaginationProvider>
          </ProductListProvider>
          <ModalRoot />
          <ScrollRestorationHandler
            ignoreForRouteTypes={[
              'sortOrderChange',
              'filterChange',
              'paginationChange'
            ]}
          />
          <CookieConsent />
        </Container>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
