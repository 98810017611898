import React, { useState } from 'react';
import { styled } from 'linaria/react';
import Button from '../ui/Button';
import { useCookies } from 'react-cookie';
import { useSiteContent } from './useSiteContent';

const animationLength = 800;

const Wrapper = styled('div')`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  padding: 2rem;
  background-color: white;
  width: 24rem;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation-name: enter;
  animation-duration: ${animationLength}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  z-index: 50;
  &.unmounting {
    animation-name: exit;
  }

  @keyframes enter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes exit {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  a {
    display: block;
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  button {
    width: 100%;
    height: 46px;
  }
`;

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const { cookieConsent } = cookies;

  const [visible, setVisible] = useState(cookieConsent !== 'agreed');
  const [unmounting, setUnmounting] = useState(false);

  const items = useSiteContent(['CookieConsent']);

  function agree() {
    setCookie('cookieConsent', `agreed`, { maxAge: 3600 * 24 * 30 });
    setUnmounting(true);
    setTimeout(() => {
      setVisible(false);
    }, animationLength);
  }

  return items && items.show && visible ? (
    <Wrapper className={unmounting ? 'unmounting' : ''}>
      {items.html}
      <Button onClick={agree}>Ok</Button>
    </Wrapper>
  ) : null;
};

export default CookieConsent;
