import React from 'react';
import { styled } from 'linaria/react';
import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';

const TextContentWrapper = styled('div')``;

export const domLink = ({ domNode, options }) => {
  if (
    domNode.name === 'a' &&
    domNode.attribs.href &&
    domNode.attribs.href.length > 0
  ) {
    const href = domNode.attribs.href;
    if (
      href.search('http') !== -1 ||
      href.search('mailto:') !== -1 ||
      href.search('tel:') !== -1
    ) {
      <a href={domNode.attribs.href} className={domNode.attribs.class}>
        {domToReact(domNode.children, options)}
      </a>;
    } else
      return (
        <Link to={domNode.attribs.href} className={domNode.attribs.class}>
          {domToReact(domNode.children, options)}
        </Link>
      );
  }
  return null;
};

export const TextContentHtmlParse = ({ content }) => {
  if (!content) return null;
  const options = {
    replace: domNode => {
      if (domNode && !domNode.attribs) return;
      return domLink({ domNode, options });
    }
  };
  return parse(content, options);
};

export const TextContent = ({ content, className = '' }) => {
  return (
    <TextContentWrapper className={`content-area ${className}`}>
      <TextContentHtmlParse content={content} />
    </TextContentWrapper>
  );
};

export default TextContent;
