import React from 'react';
import { styled } from 'linaria/react';
import NewsletterField from '../../Newsletter/NewsletterField';
import { theme } from '../../Theming/Theming';
import { useSiteContent } from '../../Util/useSiteContent';

const NewsletterBoxWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  color: ${theme.colors.white};
  .text {
    margin-bottom: 1.8rem;
  }
`;

const NewsletterBox = () => {
  const item = useSiteContent(['Newsletter']);
  if (!item || !item.show) return null;
  return (
    <NewsletterBoxWrapper>
      <div className="text">{item.html}</div>
      <NewsletterField />
    </NewsletterBoxWrapper>
  );
};

export default NewsletterBox;
