import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import LogoComponent from './LogoComponent';
import { contentTextElements } from '../ContentText';

const LogoRowWrapper = styled.div`
  .logo-row-children {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    > div {
      width: 100%;
      ${theme.above.sm} {
        width: 50%;
      }
      ${theme.above.lg} {
        width: 25%;
      }
    }
  }

  //BACKGROUND
  &.primary,
  &.secondary {
    background: ${theme.startPage.row.background.primary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.primary};
    }
    .row-header {
      margin: 0 0 1rem;
    }
    .logo-row-inner {
      display: flex;
      flex-direction: column;
    }
  }
  &.secondary {
    background: ${theme.startPage.row.background.secondary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.secondary};
    }
  }

  //MARGINS BETWEEN IMAGES
  &.margins {
    .row-header {
      margin-bottom: 1rem;
    }
    .logo-row-inner {
      max-width: ${theme.general.maxWidth};
      margin: 0 auto;
      padding: 0 ${theme.general.pagePadding.sm};
      ${theme.below.md} {
        padding: 0;
      }
      .logo-row-children {
        padding-top: 2%;
        > div {
          margin-bottom: 1.5%;
          ${theme.only.sm} {
            width: 49%;
            &:not(:nth-of-type(2n)) {
              margin-right: 2%;
            }
          }
          ${theme.above.md} {
            width: 24%;
            &:not(:nth-of-type(4n)) {
              margin-right: 1.33333333%;
            }
          }
        }
      }
    }
  }
`;

const LogoRow = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const aspect = props.imageAspectRatio.value;
  const margins = props.imageMargins.value;
  const background = props.background.value;

  let cssStyles = margins === 'yes' ? 'margins' : '';
  cssStyles += ' ' + background;

  if (data && data.children)
    return (
      <LogoRowWrapper className={`logo-row ${cssStyles}`}>
        <div className="logo-row-inner">
          <div className="logo-row-children">
            {data.children.map((child, index) => (
              <LogoComponent
                data={child}
                columns={data.children.length}
                aspect={aspect}
                key={index}
              />
            ))}
          </div>
        </div>
      </LogoRowWrapper>
    );
  else return null;
};

export default LogoRow;
