import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { useSiteContent } from '../../Util/useSiteContent';

const Wrapper = styled('div')`
  padding: 1.5rem;
  text-align: center;
  background: ${theme.footer.backgroundBottom};
  * {
    color: #aaa;
  }
  .text {
    margin-bottom: 0.5rem;
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        &:not(:first-child):before {
          content: '|';
          margin: 5px 10px;
          color: #ccc;
        }
      }
    }
  }
`;

export const FooterBottom = () => {
  const item = useSiteContent(['FooterBottom']);
  if (!item || !item.show) return null;
  return (
    <Wrapper>
      <div className="text">{item.html}</div>
    </Wrapper>
  );
};
