import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { ContentHtml, contentTextElements } from '../ContentText';

const TextBoxWrapper = styled('div')`
  .content-html-text {
    max-width: ${theme.general.maxWidthText};
    margin: 0 auto;
    padding: 0 ${theme.general.pagePadding.sm};
    &.center {
      > ul {
        display: flex;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        justify-content: space-around;
        flex-wrap: wrap;
        > li {
          margin: 5px;
          &:before {
            content: '';
          }
        }
      }
    }
  }
  padding: 2rem 0;
  &.primary {
    background: ${theme.startPage.row.background.primary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.primary};
    }
  }
  &.secondary {
    background: ${theme.startPage.row.background.secondary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.secondary};
    }
  }
`;

const TextBox = ({ data }) => {
  const props = convertPropsToObject(data);
  const text = props.text.value;
  const textAlign = props.textAlign.value;
  const textColor = props.textColor.value;
  const background = props.background.value;
  return (
    <TextBoxWrapper className={`${background}`}>
      <ContentHtml
        className={`content-html-text ${textColor} ${textAlign}`}
        content={text}
      />
    </TextBoxWrapper>
  );
};

export default TextBox;
