import React from 'react';
import { styled } from 'linaria/react';
import { useSiteContent } from '../../../Util/useSiteContent';

const TopBarLinksWrapper = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  > ul {
    > li {
    }
  }
`;

const TopBarLinks = () => {
  const items = useSiteContent(['TopBarLinks']);
  if (items && items.show)
    return (
      <TopBarLinksWrapper className="top-bar-links-list">
        {items.html}
      </TopBarLinksWrapper>
    );
  return null;
};

export default TopBarLinks;
