import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import { convertPropsToObject } from '../ContentRender';
import { theme } from '../../Theming/Theming';
import { ContentLink } from '../ContentLink';

const LogoComponentWrapper = styled.div`
  .image-component-inner {
    position: relative;
    &.box-right,
    &.box-left {
      display: flex;
      flex-wrap: wrap;
      > * {
        width: 100%;
        ${theme.above.md} {
          &.image-wrapper {
            width: 60%;
          }
          &.image-text {
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    &.box-left {
      ${theme.above.md} {
        .image-wrapper {
          order: 1;
        }
      }
    }
  }
`;

const ImageWrapper = styled('div')`
  display: block;
  overflow: hidden;
`;

const LogoComponentInner = ({ props, aspect }) => {
  const imageUrl = props.imageUrl.value;
  return (
    <div className={`image-component-inner`}>
      {imageUrl && (
        <ImageWrapper className={`image-wrapper`}>
          <Image
            className="image-holder"
            aspect={aspect}
            sizes={[1, 1, 1 / 2, 1 / 4]}
            src={imageUrl}
            alt={imageUrl}
          />
        </ImageWrapper>
      )}
    </div>
  );
};

const LogoComponent = ({ data, aspect = '1:1' }) => {
  const props = convertPropsToObject(data);
  const link = props.link.value;
  return (
    <LogoComponentWrapper className={`logo-component-wrapper`}>
      {link ? (
        <ContentLink to={link}>
          <LogoComponentInner props={props} aspect={aspect} />
        </ContentLink>
      ) : (
        <LogoComponentInner props={props} aspect={aspect} />
      )}
    </LogoComponentWrapper>
  );
};

export default LogoComponent;
