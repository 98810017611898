export const config = {
  routes: {
    favourites: {
      path: '/onskelista'
    },
    blog: {
      path: '/blogg',
      id: 198,
      mainLevel: 1,
      menu: true
    },
    brand: {
      id: 884
    },
    watches: {
      id: 388
    },
    category: {
      hide: false
    },
    product: {
      hide: false
    }
  },
  settings: {
    storeName: 'Jarl Sandin',
    siteContentId: 78,
    siteContentIdSecondChannel: 91
  }
};
