import React from 'react';
import { styled } from 'linaria/react';
import FooterContent from './FooterContent';
import NewsletterBox from './NewsletterBox';
import { theme } from '../../Theming/Theming';
import { StoreInfo } from './StoreInfo';
import { FooterBottom } from './FooterBottom';

const FooterWrapper = styled('footer')`
  background: ${theme.footer.background};
  z-index: 2;
  a,
  a:active {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper {
    background: ${theme.footer.backgroundUpper};
    > div {
      padding: 3rem ${theme.general.pagePadding.lg};
    }
    ${theme.above.lg} {
      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > div {
          width: 48%;
        }
      }
    }
    ${theme.below.md} {
      > div > div:last-child {
        margin-top: 4rem;
      }
    }
  }
  .footer-content {
    padding: 3rem 0;
    color: ${theme.footer.color};
  }
`;

const FooterMaxWidth = styled.div`
  max-width: ${theme.general.maxWidth};
  margin: 0 auto;
`;

const Footer = () => {
  return (
    <>
      <FooterWrapper>
        <div className="footer-upper">
          <FooterMaxWidth>
            <NewsletterBox />
            <StoreInfo />
          </FooterMaxWidth>
        </div>
        <div className="footer-content">
          <FooterMaxWidth>
            <FooterContent />
          </FooterMaxWidth>
        </div>
      </FooterWrapper>
      <FooterBottom />
    </>
  );
};

export default Footer;
