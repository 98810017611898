import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../Theming/Theming';
import { useSiteContent } from '../../Util/useSiteContent';

const Wrapper = styled('div')`
  ${theme.above.md} {
    display: flex;
    justify-content: space-between;
    > div {
      width: 47%;
    }
  }
  .text {
    ${theme.below.sm} {
      margin-top: 2rem;
    }
    * {
      color: ${theme.colors.white};
      line-height: 2em;
      display: block;
    }
    span {
      color: #aaa;
      margin-bottom: 1.5rem;
    }
    a {
      margin-top: 1.5rem;
    }
  }
`;

export const StoreInfo = () => {
  const item = useSiteContent(['StoreInfo']);
  if (!item || !item.show) return null;
  return (
    <Wrapper>
      <div className="image">
        <Image src={item.image} sizes={[500]} aspect="4:3" crop={true} />
      </div>
      <div className="text">{item.html}</div>
    </Wrapper>
  );
};
